var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { width: "100%", height: "100%" } },
    [
      _c("b-overlay", {
        attrs: {
          show: true,
          rounded: "sm",
          id: "loader",
          "no-wrap": "",
          fixed: "",
          "z-index": "1100"
        }
      }),
      _c("VueUnity", {
        attrs: { unity: _vm.unityContext, width: "100%", height: "100%" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }