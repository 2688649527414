<template>
  <div class="container" width="100%" height="100%">
   

      <b-overlay :show="true" rounded="sm" id="loader" no-wrap fixed z-index="1100"></b-overlay>
        
      <VueUnity :unity="unityContext" width="100%" height="100%" />
   
    
    
    
  </div>
</template>
<script>

import i18n from "@/i18n";
import UnityWebgl from 'unity-webgl';
import VueUnity from 'unity-webgl/vue';

const Unity = new UnityWebgl({
  dataUrl: "Build/test_static_post.data",
  frameworkUrl: "Build/test_static_post.framework.js",
  codeUrl: "Build/test_static_post.wasm",
  loaderUrl:"Build/test_static_post.loader.js"
});

Unity.on('created', () => {
    console.log('Unity created: success')
    document.getElementById("loader").hidden=true
})
.on('generaReport',(data)=>{
  console.log(data)
});

export default {
  name: "prodottiar",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.prodottiar"),
  },
  components: {
    // HelloWorld,
    VueUnity,
  },
  data() {
    return {
      unityContext: Unity,
    };
  },
};
</script>

<style scoped>
.text-xl {
  font-size: 1.3rem;
}
.partner {
  max-height: 150px;
  max-width: 100%;
}
</style>
