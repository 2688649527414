<template>
  <div>
    <!-- <LogoBanner /> -->
    <b-overlay :show="loading" rounded="sm">
      <div class="jumbotron m-0 p-0 p-3 jumbotron-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-row>
                  <b-col lg="6">
                    <p v-if="message" class="font-weight-bold text-danger">
                      {{ message }}
                    </p>
                    <!-- Email -->
                    <b-form-row>
                      <b-col lg="12">
                        <b-form-group
                          id="username-input-group"
                          :label="$t('username')"
                          label-for="username"
                        >
                          <b-form-input
                            id="username"
                            name="username"
                            type="text"
                            v-model="$v.form.username.$model"
                            :state="validateState('username')"
                            aria-describedby="username-live-feedback"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback id="username-live-feedback">
                            <div v-if="!$v.form.username.required">
                              {{ $t("validation.required") }}
                            </div>
                            <!-- <div v-if="!$v.form.email.email">
                          {{ $t("validation.email") }}
                        </div> -->
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <!-- Password -->
                    <b-form-row>
                      <b-col lg="12">
                        <b-form-group
                          id="name-input-group"
                          :label="$t('login.password')"
                          label-for="password"
                        >
                          <b-form-input
                            id="password"
                            name="password"
                            type="password"
                            v-model="$v.form.password.$model"
                            :state="validateState('password')"
                            aria-describedby="password-live-feedback"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback id="password-live-feedback">
                            {{ $t("validation.required") }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                    <!-- Register Link -->
                    <!-- <b-form-row>
                      <p>
                        Non hai un account?
                        <router-link :to="{ name: 'register' }"
                          >Registrati
                        </router-link>
                      </p>
                    </b-form-row> -->
                    <!-- Buttons -->
                    <b-form-row>
                      <b-col md="12" class="text-right">
                        <b-button type="submit" variant="success"
                          >{{ $t("login.login") }}
                        </b-button>
                        <b-button class="ml-2" @click="resetForm()"
                          >{{ $t("register.clear") }}
                        </b-button>
                      </b-col>
                    </b-form-row>
                  </b-col>
                  <b-col lg="6">
                    <!-- Right Image -->
                    <img
                      :alt="$t('menu.about')"
                      src="@/assets/images/login.png"
                    />
                  </b-col>
                </b-form-row>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  name: "Login",
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.login"),
    meta: [{ name: "description", content: i18n.t("menu.login") }],
  },
  props: {
    message: {
      type: String,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["account/loading"];
    },
  },
  validations: {
    form: {
      username: {
        required,
        // email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("account", ["login"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        password: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        // alert("Errors!");
        return;
      }
      const { username, password } = this.form;
      const redirect = this.$route.query.redirect;
      this.login({ username, password, redirect });
    },
  },
};
</script>
