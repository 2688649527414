var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c("div", { staticClass: "jumbotron m-0 p-0 p-3 jumbotron-fluid" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12 col-lg-12" },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "6" } },
                            [
                              _vm.message
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-bold text-danger"
                                    },
                                    [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { lg: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "username-input-group",
                                            label: _vm.$t("username"),
                                            "label-for": "username"
                                          }
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "username",
                                              name: "username",
                                              type: "text",
                                              state: _vm.validateState(
                                                "username"
                                              ),
                                              "aria-describedby":
                                                "username-live-feedback"
                                            },
                                            model: {
                                              value:
                                                _vm.$v.form.username.$model,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.$v.form.username,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.form.username.$model"
                                            }
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              attrs: {
                                                id: "username-live-feedback"
                                              }
                                            },
                                            [
                                              !_vm.$v.form.username.required
                                                ? _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "validation.required"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-form-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { lg: "12" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "name-input-group",
                                            label: _vm.$t("login.password"),
                                            "label-for": "password"
                                          }
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "password",
                                              name: "password",
                                              type: "password",
                                              state: _vm.validateState(
                                                "password"
                                              ),
                                              "aria-describedby":
                                                "password-live-feedback"
                                            },
                                            model: {
                                              value:
                                                _vm.$v.form.password.$model,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.$v.form.password,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.form.password.$model"
                                            }
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              attrs: {
                                                id: "password-live-feedback"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "validation.required"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-form-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "submit",
                                            variant: "success"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("login.login")) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.resetForm()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("register.clear")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { lg: "6" } }, [
                            _c("img", {
                              attrs: {
                                alt: _vm.$t("menu.about"),
                                src: require("@/assets/images/login.png")
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }